import React, { useContext } from 'react';
import { Container, Dropdown, Image, Nav, Navbar } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { NavLink, useNavigate } from 'react-router-dom';
import authContext from '../context/auth-context';
import BrandsContext from '../context/brands-context';
import { APP_VERSION } from '../utils/constants';

const Header = () => {
  const navigate = useNavigate();
  const auth = useContext(authContext);
  const { brands, currentBrand } = useContext(BrandsContext);
  const isOpenThreads = brands.reduce((a, b) => a + b.open_threads, 0) > 0;
  return (
    <Navbar bg='light' expand='lg' fixed='top' style={{ alignItems: 'flex-end' }}>
      <Container>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Brand
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate('/');
          }}
        >
          <Image src='/8alert_logo2.png' height={50} />
        </Navbar.Brand>
        <Navbar.Collapse id='basic-navbar-nav' style={{ marginRight: 10 }}>
          <Nav className='me-auto' style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <NavLink style={{ alignSelf: 'center' }} className='btn nav-link' to='/'>
              Home
            </NavLink>
            {currentBrand && (
              <div>
                <h2 style={{ textAlign: 'center' }}>{currentBrand.name}</h2>
                <h5 style={{ textAlign: 'center' }}>{`Open Threads: ${currentBrand?.open_threads}`}</h5>
              </div>
            )}
            {auth.user && (
              <Dropdown id='basic-nav-dropdown'>
                <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                  Switch Brand
                  {isOpenThreads && (
                    <span
                      style={{
                        display: 'inline-block',
                        width: 14,
                        height: 14,
                        backgroundColor: 'red',
                        marginInlineStart: 10,
                        borderRadius: 10,
                        fontSize: 10,
                        alignItems: 'center',
                        justifyContent: 'center',
                        // marginBottom: 2,
                        textAlign: 'center',
                      }}
                    />
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {brands.map((brand) => (
                    <DropdownItem
                      onClick={() => {
                        navigate(`/brand/${brand.id}`);
                      }}
                      key={'brand#' + brand.id}
                      style={{ flex: 1, flexDirection: 'row' }}
                    >
                      <span style={{ display: 'inline-block' }}>{brand.name}</span>
                      {brand && (
                        <span
                          style={{
                            display: 'inline-block',
                            width: 14,
                            height: 14,
                            color: '#fff',
                            fontWeight: 'bold',
                            backgroundColor: brand?.open_threads > 0 ? 'red' : 'lime',
                            marginInlineStart: 10,
                            borderRadius: 10,
                            fontSize: 10,
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                          }}
                        >
                          {brand?.open_threads > 0 ? brand?.open_threads : '✓'}
                        </span>
                      )}
                    </DropdownItem>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Nav>
          <div style={{ textAlign: 'center', paddingInlineStart: 10 }}>
            <Nav>
              {auth.user ? (
                <Nav.Link variant='outline-danger' onClick={auth.logout}>
                  Logout
                </Nav.Link>
              ) : (
                <Nav.Link href='/login'>Login</Nav.Link>
              )}
            </Nav>
            <span style={{ fontSize: 12 }}>{`Ver ${APP_VERSION}`}</span>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
