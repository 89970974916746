import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';

const ForumMessage = ({ message, setImageToShow }) => {
  return (
    <>
      <Container style={{ borderBottom: '1px dotted #c0c0c0', paddingBottom: 5 }}>
        <Row>
          <Col sm={2}>
            <Row>
              <span>{message.user.name}</span>
            </Row>
            <Row>
              <span style={{ fontSize: 12 }}>{message.created_at}</span>
              <span style={{ fontSize: 12 }}>{`#${message.id}`}</span>
            </Row>
          </Col>
          <Col sm={9}>
            <Row>
              <Col>
                <p style={{ whiteSpace: 'pre-line' }}>{message.body}</p>
              </Col>
              <Col sm={2}>
                <Image
                  onClick={() => {
                    setImageToShow(message.image);
                  }}
                  src={message.image}
                  style={{ maxWidth: 100, cursor: 'pointer' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForumMessage;
