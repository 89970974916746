import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import AuthContext from './context/auth-context';
import brandsContext from './context/brands-context';
import { RequireAuth } from './context/RequireAuth';
import Forum from './pages/Forum/Forum';
import Login from './pages/Login/Login';
import Main from './pages/Main/Main';

function App() {
  const logout = useCallback(() => {
    setUser(null);
  }, []);
  const [user, setUser] = useState();
  const [token, setToken] = useState();

  const [brands, setBrands] = useState([]);
  const [currentBrand, setCurrentBrand] = useState();

  //fetch all brands again
  const updateCompanies = useCallback((token) => {
    axios
      .post('https://signal500.com/api/getForumCompanies', { token })
      .then((response) => {
        //Update CurrentBrand if exists...
        if (currentBrand != null)
          setCurrentBrand(response.data.companies_ids.find((brand) => brand.id === currentBrand.id));
        //update Brands
        setBrands(response.data.companies_ids);
      })
      .catch((err) => {
        console.log(err);
      });
  });
  useEffect(() => {
    if (token) updateCompanies(token);
  }, [token]);
  return (
    <brandsContext.Provider value={{ brands: brands, updateBrands: updateCompanies, currentBrand, setCurrentBrand }}>
      <AuthContext.Provider value={{ token, setToken, user, setUser, logout }}>
        <Header brands={brands} />
        <main>
          <Routes>
            <Route
              path='/brand/:company_id'
              element={
                <RequireAuth>
                  <Forum />
                </RequireAuth>
              }
            />

            <Route path='/login' element={<Login />} />
            <Route path='/' element={<Login />} />
            <Route exact path='/main' element={<Main />} />
          </Routes>
        </main>
        {/* <Footer /> */}
      </AuthContext.Provider>
    </brandsContext.Provider>
  );
}
export default App;
