import React, { useContext } from 'react';
import { Stack } from 'react-bootstrap';
import AuthContext from '../../context/auth-context';

const Main = () => {
  const auth = useContext(AuthContext);
  return (
    <Stack gap={2} style={{ display: 'flex', alignItems: 'center' }}>
      <h1>8Alerts Forum Panel</h1>
      {auth.user ? <h2>Hello, {auth.user.first_name}</h2> : <p className='text-danger'>Please Login to continue</p>}
    </Stack>
  );
};

export default Main;
