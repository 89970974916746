import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import AuthContext from '../../context/auth-context';
import BrandsContext from '../../context/brands-context';
import { server_url } from '../../utils/constants';
import { fromBase64, toBase64 } from '../../utils/functions';
import ForumMessage from '../ForumMessage/ForumMessage';
import PostMessagePanel from '../PostMessagePanel/PostMessagePanel';

const ForumSubject = ({ subject, setImageToShow, fetchSubjects }) => {
  const [subjectMessages, setSubjectMessages] = useState();
  const [showAll, setShowAll] = useState(false);
  const [show, setShow] = useState(false);
  const auth = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const { updateBrands } = useContext(BrandsContext);

  const [error, setError] = useState();

  const handleHandled = async (handled) => {
    try {
      setError(null);
      setIsLoading(true);
      await axios.post(`${server_url}forum_handled`, {
        token: auth.token,
        company_id: subject.company_id,
        message_id: subjectMessages[subjectMessages.length - 1].id,
        handled,
      });
      updateBrands(auth.token);
      fetchSubjects()
      fetchMessages(subject);
    } catch (error) {
      const respErr = error.response.data;
      setError(respErr?.validator[0] || respErr?.errorDisplay || respErr?.error);
    } finally {
      setIsLoading(false);
    }
  };
  const postMessage = async (msgData, isSubject = false) => {
    let postMessage = {
      token: auth.token,
      title: '',
      body: toBase64(msgData.body),
      base64: true,
      company_id: subject.company_id,
      category_id: subject.forum_category.id,
      parent_id: subject.id,
      sub_parent_id: subject.id,
      image: msgData.image ? msgData.image : null,
    };
    try {
      setError(null);
      setIsLoading(true);
      await axios.post(`${server_url}postForumMessage`, postMessage);
      fetchMessages(subject);
      updateBrands();
      return true;
    } catch (error) {
      const respErr = error.response.data;
      setError((respErr?.validator?.length > 0 && respErr.validator[0]) || respErr?.errorDisplay || respErr?.error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendMessageButtonClick = async (msgData) => {
    return await postMessage(msgData);
  };
  const fetchMessages = async (_subject) => {
    try {
      const response = await axios.post(`${server_url}getForumMessages`, {
        category_id: _subject.forum_category_id,
        parent_id: _subject.id,
        company_id: _subject.company_id,
      });
      setSubjectMessages(
        response.data.result.map((message) =>
          message.base64
            ? {
                ...message,
                body: fromBase64(message.body),
                title: fromBase64(message.title),
              }
            : message
        )
      );
    } catch (error) {}
  };
  useEffect(() => {
    fetchMessages(subject);
  }, [subject]);

  const open =
    subjectMessages?.length &&
    !subject?.is_thread_handled &&
    !subjectMessages[subjectMessages?.length - 1].handled &&
    !subject.handled;
  return (
    <Card className='mb-2'>
      <Card.Header>
        <Container>
          <Row>
            <Col md={10} xs={8}>
              <h3 style={{ display: 'inline' }}>{`${subject.title}`}</h3>
              <h5>{`${subject.user.name}`}</h5>
            </Col>
            <Col
              style={{ cursor: 'pointer', textAlign: 'right' }}
              onClick={() => {
                setShow((show) => !show);
              }}
            >
              {<p className={open ? 'text-danger' : 'text-success'}>{open ? 'Opened Thread' : 'Closed Thread'}</p>}
              {show ? <MdExpandLess /> : <MdExpandMore />}
            </Col>
          </Row>
        </Container>
      </Card.Header>

      {show && (
        <Card.Body>
          {subjectMessages && (
            <>
              {!showAll && subjectMessages.length > 5 && (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setShowAll(true);
                  }}
                >
                  Show More
                </div>
              )}
              {subjectMessages
                .sort((a, b) => a.id - b.id)
                .slice(showAll ? null : -5)
                .map((message, index) => (
                  <ForumMessage setImageToShow={setImageToShow} message={message} key={`forum-msg#${index}`} />
                ))}
            </>
          )}

          {open ? (
            <PostMessagePanel
              onSend={(msgData) => handleSendMessageButtonClick(msgData)}
              error={error}
              isLoading={isLoading}
              onPostMessage={(body) => {
                postMessage(body);
              }}
              handleHandled={handleHandled}
            />
          ) : (
            <div>
              <p className='text-success'>Closed Thread</p>
              <Button onClick={() => handleHandled(0)}>Reopen Thread</Button>
            </div>
          )}
        </Card.Body>
      )}
    </Card>
  );
};

export default ForumSubject;
