import axios from 'axios';
import React, { useState } from 'react';
import { useContext } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import BrandsContext from '../../context/brands-context';
import { server_url } from '../../utils/constants';
import { fromBase64 } from '../../utils/functions';
import ForumSubject from '../ForumSubject/ForumSubject';

const ForumCategory = ({ category, showEmpty, setImageToShow, showAll }) => {
  const { currentBrad } = useContext(BrandsContext);
  const [subjects, setSubjects] = useState();
  const fetchSubjects = useCallback(async () => {
    const _subjects = await axios.post(server_url + 'getForumMessages', {
      company_id: 1 * category.company_id,
      category_id: category.id,
    });
    if (_subjects.data.success) {
      setSubjects(
        _subjects.data.result.map((message) => ({
          ...message,
          title: message.base64 ? fromBase64(message.title) : message.title,
        }))
      );
    }
  }, [category, currentBrad]);
  useEffect(() => {
    fetchSubjects();
  }, [fetchSubjects]);

  const isEmpty = !category.subjects?.length;
  const filteredSubjects = subjects
    ?.filter((subject) => showAll || (!subject.is_thread_handled && !subject.handled))
    ?.sort((s1, s2) => s1.is_thread_handled - s2.is_thread_handled);
  return !isEmpty || showEmpty ? (
    <div style={{ marginBottom: '1rem' }} key={'cat#' + category.id}>
      <h2 style={{ color: isEmpty ? '#c0c0c0' : 'black' }}>{category.description}</h2>
      {filteredSubjects?.length > 0 ? (
        filteredSubjects.map((subject) => (
          <ForumSubject
            key={`subject${subject.id}`}
            setImageToShow={setImageToShow}
            subject={subject}
            fetchSubjects={fetchSubjects}
          />
        ))
      ) : (
        <p>Nothing to show</p>
      )}
    </div>
  ) : (
    <></>
  );
};

export default ForumCategory;
