import { createContext } from 'react';
const AuthContext = createContext({
  setUser: () => {},
  user: {},
  token: null,
  setToken: () => {},
  login: () => {},
  logout: () => {},
});

export default AuthContext;
