import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Container, Form, Image, Modal, Row, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ForumCategory from '../../components/ForumCategory/ForumCategory';
import BrandsContext from '../../context/brands-context';
import { server_url } from '../../utils/constants';

const Forum = () => {
  const [imageToShow, setImageToShow] = useState();
  const [categories, setCategories] = useState([]);
  const { company_id } = useParams();
  const [showAll, setShowAll] = useState(false);
  useEffect(() => {
    const fetchCategories = async () => {
      setCurrentBrand(brands.find((brand) => '' + brand.id === company_id));
      setCategories([]);
      const _categories = await axios.post(server_url + 'getForumCategories', {
        company_id: 1 * company_id,
      });
      setCategories(_categories.data.result);
    };

    fetchCategories();
  }, [company_id, ]);
  const { brands, setCurrentBrand } = useContext(BrandsContext);
  return (
    <>
      <Modal
        show={imageToShow}
        centered
        onHide={() => {
          setImageToShow(null);
        }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Image src={imageToShow} />
      </Modal>
      <Container>
        <Form.Check
          className='mb-2'
          type='checkbox'
          label='Show Closed Threads'
          checked={showAll}
          onChange={(e) => {
            setShowAll(e.target.checked);
          }}
        />
        {categories ? (
          categories.map((category) => (
            <ForumCategory
              showAll={showAll}
              key={`cat#${category.id}`}
              showEmpty
              category={category}
              setImageToShow={setImageToShow}
            />
          ))
        ) : (
          <Row style={{ justifyContent: 'center' }}>
            <Spinner animation='grow' />
          </Row>
        )}
      </Container>
    </>
  );
};

export default Forum;
