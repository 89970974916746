import React, { useRef, useState } from 'react';
import { Button, Col, Form, Image, Row, Spinner } from 'react-bootstrap';
import { AiOutlineLock, AiOutlineSend } from 'react-icons/ai';
import { imageToBase64 } from '../../utils/functions';

const PostMessagePanel = ({ isLoading, error, onSend, handleHandled }) => {
  const [image, setImage] = useState();
  const [messageBody, setMessageBody] = useState();
  const imageInputRef = useRef();
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      imageToBase64(img, (res) => {
        setImage({ base64: res.split(',')[1] || null, image: URL.createObjectURL(img) });
      });
    }
  };
  const handleSend = async (e) => {
    e.preventDefault();
    const msgData = { body: messageBody, image: image?.base64 };
    if (await onSend(msgData)) {
      setMessageBody('');
      setImage(null);
    }
  };

  return (
    <Form className='flex mt-3'>
      <Form.Group className='mb-3'>
        <Form.Control
          as='textarea'
          rows={3}
          value={messageBody}
          onChange={(e) => {
            setMessageBody(e.target.value);
          }}
        />
      </Form.Group>
      {error && (
        <p className='text-danger' style={{ fontSize: 12 }}>
          {error}
        </p>
      )}
      <Form.Group style={{ justifyContent: 'space-between' }} as={Row}>
        <Col md={6} sm={4}>
          <Button variant='danger' onClick={() => handleHandled(1)}>
            Mark Thread as Closed <AiOutlineLock />
          </Button>
        </Col>

        {isLoading ? (
          <Spinner style={{ alignSelf: 'center' }} animation='grow' />
        ) : (
          <>
            <Col sm={4}>
              <Form.Control ref={imageInputRef} type='file' multiple accept='image/*' onChange={onImageChange} />

              {image && (
                <Button className='mt-2'
                  onClick={(e) => {
                    imageInputRef.current.value = null;
                    setImage(null);
                  }}
                >
                  Remove Image
                </Button>
              )}
            </Col>
            <Col sm={2}>
              <Button onClick={handleSend}>
                Send <AiOutlineSend />
              </Button>
            </Col>
          </>
        )}
      </Form.Group>
      {image && <Image src={image?.image} style={{ maxWidth: 400 }} />}
    </Form>
  );
};

export default PostMessagePanel;
