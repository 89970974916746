import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import authContext from '../../context/auth-context';
import { $STORAGE_USER_EMAIL, $STORAGE_USER_PASS, server_url } from '../../utils/constants';
import { decrypt, encrypt } from '../../utils/functions';

const Login = () => {
  const [loginData, setLoginData] = useState({ email: '', password: '', company_id: 16 });
  const [error, setError] = useState();
  const auth = useContext(authContext);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const login = async (loginData) => {
    try {
      const response = await axios.post(server_url + 'login', loginData);

      if (response.data.success) {
        auth.setUser(response.data.user);
        auth.setToken(response.data.token);

        if (rememberMe) {
          localStorage.setItem($STORAGE_USER_EMAIL, encrypt(loginData.email));
          localStorage.setItem($STORAGE_USER_PASS, encrypt(loginData.password));
        }
        navigate('/main');
      }
    } catch (err) {
      console.log(err);
      setError(err?.response?.data?.errorDisplay || err);
    }
  };
  const handleClick = async (e) => {
    setError(null);
    e.preventDefault();

    login(loginData);
  };
  const getStoredData = () => {
    let email = localStorage.getItem($STORAGE_USER_EMAIL);
    let password = localStorage.getItem($STORAGE_USER_PASS);
    if (email && password) {
      email = decrypt(email);
      password = decrypt(password);
      login({ email, password, company_id: 16 });
    }
  };
  useEffect(() => {
    getStoredData();
    return () => {};
  }, [login]);
  return (
    <Container>
      {auth.user ? (
        <div>
          <h2>Logout</h2>
        </div>
      ) : (
        <>
          <h1>Login</h1>
          <Form>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                value={loginData.email}
                onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
              />
              <Form.Text className='text-muted'>We'll never share your email with anyone else.</Form.Text>
            </Form.Group>

            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Password'
                value={loginData.password}
                onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Check
                type='checkbox'
                label='Remember Me'
                checked={rememberMe}
                onChange={(e) => {
                  setRememberMe(e.target.checked);
                }}
              />
              {error && <Form.Text className='mb-3 text-danger'>{error}</Form.Text>}
            </Form.Group>
            <Button
              variant='primary'
              type='submit'
              onClick={handleClick}
              onChange={(e) => {
                setRememberMe(e.target.checked);
              }}
            >
              Submit
            </Button>
          </Form>
        </>
      )}
    </Container>
  );
};

export default Login;
