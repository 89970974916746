import { Buffer } from 'buffer';
import { AES, enc } from 'crypto-js';
import { key } from './constants';
export function fromBase64(str) {
  return Buffer.from(str, 'base64').toString('utf-8');
}
export function toBase64(str) {
  try {
    return Buffer.from(str, 'utf-8').toString('base64');
  } catch (error) {
    return str;
  }
}

export function encrypt(strToEncrypt) {
  return AES.encrypt(strToEncrypt, key);
  // return myCipher(strToEncrypt);
}

export function decrypt(strToDecrypt) {
  return AES.decrypt(strToDecrypt, key).toString(enc.Utf8);
}

export const imageToBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
};
