import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import authContext from './auth-context';

export const RequireAuth = (props) => {
  let auth = useContext(authContext);

  if (!auth.user) {
    return <Navigate to='/login' />;
  }
  return props.children;
};
